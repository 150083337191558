// import firebase from "firebase/compat/app";
// Required for side-effects
import "firebase/firestore";

import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC4wNZz6kbwPv5ZZRpPzVQLZKdw40C5j0g",
  authDomain: "zero-scales.firebaseapp.com",
  projectId: "zero-scales",
  storageBucket: "zero-scales.appspot.com",
  messagingSenderId: "582496908311",
  appId: "1:582496908311:web:822ef6609524f833359852",
  measurementId: "G-0W4MK7PRD2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export const storeSignup = async (
  email: string,
  onSuccess: (id: string) => void
) => {
  const docRef = await addDoc(collection(db, "signups"), {
    email,
  });
  //   console.log("Document written with ID: ", docRef.id);

  onSuccess(docRef.id);
};
