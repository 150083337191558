import React from "react";
import "./App.css";
// 1. import `ChakraProvider` component
import {
  Box,
  Button,
  ChakraProvider,
  FormControl,
  IconButton,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { MdClose, MdMail, MdMailOutline, MdPlayCircle } from "react-icons/md";
import { storeSignup } from "./firebase";

const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const App = () => {
  const [openVideo, setOpenVideo] = React.useState(false);
  const [openMobileVideo, setOpenMobileVideo] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(0);
  const [signupEmail, setSignupEmail] = React.useState("");

  return (
    <ChakraProvider>
      <div className="App">
        <div className="App-content">
          <h1>
            ZER<span className="App-logo">Ø</span>
          </h1>
          <h2>smart scales with a difference</h2>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={5}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Button
              colorScheme="teal"
              variant="solid"
              // fontWeight={800}
              onClick={() => setOpenForm(1)}
              width={{ base: "100%", md: "auto" }}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              leftIcon={<Icon as={MdMailOutline} />}
            >
              Follow Progress
            </Button>
            <Button
              colorScheme="teal"
              variant="solid"
              // fontWeight={800}
              onClick={() => setOpenVideo(true)}
              width={{ base: "100%", md: "auto" }}
              // hidden on mobile
              display={{ base: "none", md: "flex" }}
              sx={{
                alignItems: "center",
              }}
              leftIcon={<Icon as={MdPlayCircle} />}
            >
              What are Zerø Scales?
            </Button>
            <Button
              colorScheme="teal"
              variant="solid"
              // fontWeight={800}
              onClick={() => setOpenMobileVideo(true)}
              width={{ base: "100%", md: "auto" }}
              // only visible on mobile
              display={{ base: "flex", md: "none" }}
              sx={{
                alignItems: "center",
              }}
              leftIcon={<Icon as={MdPlayCircle} />}
            >
              What are Zerø Scales?
            </Button>
          </Box>
          <Modal
            isOpen={openVideo}
            onClose={() => setOpenVideo(false)}
            isCentered
            size="xl"
          >
            <ModalOverlay zIndex={99} />
            <ModalContent
              width={560}
              height={315}
              maxWidth="calc(100vw - 16px)"
              position="relative"
            >
              <Box
                // position dead center
                position="absolute"
                top="calc(50% - 96px)"
                left="calc(50% - 64px)"
                zIndex={1}
              >
                <span className="spinner">Ø</span>
              </Box>
              <Box zIndex={2}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/zgPv9aIQWuI?si=mN6Qo7rKFMqxA_e8&autoplay=1"
                  title="YouTube video player"
                  style={{
                    maxWidth: "calc(100vw - 16px)",
                  }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </Box>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={openMobileVideo}
            onClose={() => setOpenMobileVideo(false)}
            isCentered
            size="xl"
          >
            <ModalOverlay zIndex={99} />
            <ModalContent
              width={414}
              height={736}
              maxWidth="calc(100vw - 16px)"
              position="relative"
            >
              <Box
                // position dead center
                position="absolute"
                top="calc(50% - 96px)"
                left="calc(50% - 64px)"
                zIndex={1}
              >
                <span className="spinner">Ø</span>
              </Box>
              <Box zIndex={2}>
                <iframe
                  width="414"
                  height="736"
                  src="https://www.youtube.com/embed/pkl02xmMwvY?autoplay=1"
                  title="What are Zerø Scales?"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </Box>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={openForm > 0}
            onClose={() => setOpenForm(0)}
            isCentered
            size="xl"
          >
            <ModalOverlay zIndex={99} />
            <ModalContent
              // width="max-content"
              width={500}
              // height={300}
              maxWidth="calc(100vw - 16px)"
              maxHeight="calc(100vh - 16px)"
              // zIndex={2}
              p={4}
            >
              <ModalHeader mt={0} pt={0} pl={0}>
                Follow Progress Updates
              </ModalHeader>
              {/* <Box
                // position dead center
                position="absolute"
                top="calc(50% - 96px)"
                left="calc(50% - 64px)"
                zIndex={1}
              >
                <span className="spinner">Ø</span>
              </Box> */}
              {openForm === 1 && (
                <Box pb={4}>
                  'Zerø Scales' are currently in development. Add your email
                  address below to receive updates on our progress.
                </Box>
              )}
              <Box>
                {openForm === 1 ? (
                  <FormControl display="flex" flexDirection="column" gap={2}>
                    <Input
                      type="email"
                      placeholder="Your Email"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}
                      isInvalid={
                        Boolean(signupEmail) && !isValidEmail(signupEmail)
                      }
                    />
                    <Box
                      display="flex"
                      flexDirection={{ base: "column", md: "row" }}
                      gap={2}
                      justifyContent="space-around"
                    >
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        onClick={() => setOpenForm(0)}
                        width="100%"
                        // width="max-content"
                        size="sm"
                      >
                        Cancel
                      </Button>
                      <Button
                        colorScheme="teal"
                        variant="solid"
                        // fontWeight={800}
                        // onClick={() => setOpenForm(true)}
                        width="100%"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          px: 8,
                        }}
                        onClick={() => {
                          // console.log("click");
                          // submit form
                          // setOpenForm(0);
                          storeSignup(signupEmail, (id) => {
                            // console.log("Document written with ID: ", id);
                            setOpenForm(2);
                          });
                        }}
                        disabled={!(signupEmail && isValidEmail(signupEmail))}
                        isDisabled={!(signupEmail && isValidEmail(signupEmail))}
                        // disabled

                        size="sm"
                        // leftIcon={<Icon as={MdMailOutline} />}
                      >
                        Keep me in the loop!
                      </Button>
                    </Box>
                  </FormControl>
                ) : (
                  <FormControl>
                    <Box mb={4}>
                      Thank you for signing up. We will keep you updated on our
                      progress.
                    </Box>
                    <Button
                      colorScheme="teal"
                      variant="solid"
                      // fontWeight={800}
                      onClick={() => setOpenForm(0)}
                      width="100%"
                      // width="max-content"
                      size="sm"
                    >
                      Close
                    </Button>
                  </FormControl>
                )}
                {/* <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSebneUDMlBW4CD-E9f3NbQUtnouTmo3KKb1L9GYBQv2SEWDvA/viewform?embedded=true"
                  width="640"
                  height="600"
                  style={{
                    maxWidth: "calc(100vw - 16px)",
                    maxHeight: "calc(100vh - 16px)",
                    // zIndex: 2,
                  }}
                  title="ZERØ Sign-Up Form"
                >
                  Loading…
                </iframe> */}
              </Box>
            </ModalContent>
          </Modal>

          <IconButton
            // close button in very top right of screen when any modals are open
            position="fixed"
            top={2}
            right={2}
            onClick={() => {
              setOpenVideo(false);
              setOpenMobileVideo(false);
              setOpenForm(0);
            }}
            aria-label="Close"
            icon={
              <Icon
                as={MdClose}
                sx={{
                  width: 8,
                  height: 8,
                  mt: 0.5,
                }}
              />
            }
            display={
              openVideo || openMobileVideo || openForm > 0 ? "block" : "none"
            }
            borderRadius={25}
            p={0}
            colorScheme="teal"
            // make it display on top of the modals:
            zIndex={1000}
          />

          <footer>
            <a href="mailto:chris@mento.co.uk" title="Contact">
              <Box
                backgroundColor="black"
                borderRadius={25}
                p={1}
                width="40px"
                height="40px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Icon as={MdMail} />
              </Box>
            </a>
            <a
              href="https://www.tiktok.com/@zeroscales"
              title="Zerø Scales on TikTok"
            >
              <img
                src="tiktok-200.png"
                alt="@zeroscales on TikTok"
                width="40"
                height="40"
              />
            </a>
            <a href="https://mento.co.uk" title="Mento Apps Ltd">
              <img
                src="mento.png"
                alt="Mento Apps Ltd"
                width="40"
                height="40"
              />
            </a>
          </footer>
        </div>
      </div>
    </ChakraProvider>
  );
};

export default App;
